
import FunFacts from "./FunFacts";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ReactCarousel from "./ReactCarousel";


const AboutMe = () => {

	const style = {color: '#0e65d7'};
    const sm = '12', lg = '6';// xm = 1,

    return <>
        <div className='about-area mt-5 pb-3'>
			<div className="text-center pb-3 border-bottom-0">
				<h1 style={style}>
					The Leading Tax Service Provider
				</h1>
				<h2>
					<span style={style} >for </span>your <span style={style} >personal</span>,
					<span style={style} > and </span>your <span style={style} >business </span>needs!
				</h2>
			</div>
			<FunFacts />
			<Container className="justify-items-center">
				<Row className="justify-items-center">
					<Col sm={sm} lg={lg} >
						<Row>
							<div className="pt-3">
								<h5 className="text-center" >Who I am!</h5>
									<p>
										Hello My name is Jasia Davenport and I'm happily married and a mother of 3 wonderful children who are the joy of my life. I moved out at 16yrs old due to family issues. I worked through HS and I moved into my own apartment at 18. Life was not easy and I had to work everyday to eat, on top of having to do the school work. Then, I found out I was with my first child on graduation date. I went to college and stayed on campus housing until I delivered.
									</p>
									<p>
										I was raised by a single parent and I knew from experience that it would be a struggle at a young age. I worked and took care of a small child for the first 2 yrs to finish an AAS in Business Administration - with minor in HR.  There I was bringing a child into the world, while in college, and no family support, I was in a state of overwhelming desperation and someone talked to me about tax preparation so at 19 I started doing Taxes also while in college and working.
									</p>
									<p>
										I started with Friends and family taxes and then learned how to market myself. I expanded my clients to other people I didn't know by way of referrals and marketing. I did a lot of self study and reading on the IRS website and other resources. I was also able to learn  by watching other tax preparers and look through previous returns. I always did love working with numbers, so it didn't take much to figure it out.
									</p>
									<h5 className="text-center" >My Commitment to You!</h5>
									<p>
										I truly enjoy working with you and helping your family and business with your taxes. My goal is to create a platform based on trust where people can have freedom to advance as far as they'd like to go, and without the pressure to build a business.  Here you have the freedom to help clients and make money without the fear of being cheated out of your hard earned fees, or the fear of being locked out of your software. I provide an environment where you can grow through training, mentorship, and trust.
									</p>
							</div>
							<NavLink to="/contact" className=" mt-3 btn btn-outline-primary">
								Contact us
							</NavLink>
						</Row>
					</Col>
					
					<Col className="d-flex align-items-center pt-5" sm={sm} lg={lg}>
						<Row>
							<ReactCarousel page='aboutme'/>
						</Row>
					</Col>
				</Row>
			</Container>
		</div>
	</>;
};

export default AboutMe;
