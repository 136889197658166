
import { useThemeContext } from "../../hooks/usethemeContext";
import { switchTheme } from '../../assets/styles';
import { Icon } from "@iconify/react";
import ThemeSwitch from '../sub-components/buttons/ThemeSwitch';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from '../../assets/images/logo.webp';

export default function NavBar() {

  // define button style by darkMode
  const { darkMode } = useThemeContext();
  const { variant, bg, iconStyle } = switchTheme(darkMode);


  return <>
    <div className='pb-3 mt-5'>
      <Navbar fixed="top" collapseOnSelect expand="md" bg={bg} variant={variant}>
        <Container>
          <Navbar.Brand href="/">
            <img
              src={Logo}
              width={"45%"}
              height={"45%"}
              className="d-inline-block align-top"
              alt="Company Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">Welcome</Nav.Link>
              <Nav.Link href="/clients">Client Services</Nav.Link>
              <Nav.Link href="/business-opportunities">Business Opportunities</Nav.Link>
              <Nav.Link href="/contact">Contact Us</Nav.Link>
            </Nav>
            <Nav className="font-weight-light mx-2">
              <Nav.Link href={process.env.REACT_APP_FB} target="_blank" rel='noreferrer'>
                <Icon icon="iconoir:facebook-squared" style={ iconStyle }/>
              </Nav.Link>
              <Nav.Link href={process.env.REACT_APP_IG} target="_blank" rel='noreferrer'>
                <Icon icon="ph:instagram-logo" style={ iconStyle }/>
              </Nav.Link>
              {/* <Nav.Link eventKey={2} href="#memes">
                Dank memes
              </Nav.Link> */}
            </Nav>
            <Nav>
              <ThemeSwitch />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  </>;
}
