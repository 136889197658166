
import Carousel from 'react-bootstrap/Carousel';
import pic1 from '../../assets/images/pic1.webp';
// import pic2 from '../../assets/images/pic2.webp';
import pic3 from '../../assets/images/pic3.webp';


export default function ReactCarousel(props) {
  const intervalTime = 5000, corners = 3;

  // resize the images according to the original size
  const styles = {
    margin: props.page !== 'aboutme' ? 'auto' : 'auto',
    width: props.page !== 'aboutme' ? '60%' : '70%',
  };


  return <>
    <Carousel fade >
      <Carousel.Item interval={intervalTime}>
        <img
          className={`rounded-${corners} d-block`}
          style={styles}
          src={ props.page !== 'aboutme' ? pic1 : pic3 }
          alt="First slide"
        />
        {/* <Carousel.Caption>
          <h5>First slide label</h5>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
      {/* { props.page !== 'aboutme' ?
        <Carousel.Item interval={intervalTime}>
            <img
            className={`rounded-${corners} d-block`}
            style={styles}
            src={ pic2 }
            alt="Second slide"
            />
        </Carousel.Item>
        :
        null
      } */}
    </Carousel>
  </>;
}