
export const dealType = {
    taxOffice: 'Tax Office',
    taxAffiliate: 'Tax Affiliate',
    mentorship: 'Mentorship',
};

export function getTaxSoftwareText(mentorshiptStyle, promo, earlySpecialHTML) {
    return {
        taxOffice: <>
            {
                promo ? 
                    <>  
                        <p>{`Regular Price $299`}</p>
                        {earlySpecialHTML}
                    </>
                :
                <></>
            }
            <p><strong>Unlimited</strong> number of users</p>
            <p>1 Business Software</p>
            <p>Branded Taxes To Go App</p>
            <p>Mentorship & Marketing Training</p>
            <p>Top Tier Tech Support</p>
        </>,
        taxAffiliate: <>
            {
                promo ? 
                    <>  
                        <p>{`Regular Price $399`}</p>
                        {earlySpecialHTML}
                    </>
                :
                <></>
            }
            <p>1 Tax Office User ID</p>
            <p>Prep-Fee Split - XYZ Client Volume</p>
            <p>Basic Tax Training</p>
            <p>Mentorship & Marketing Training</p>
            <p>Top Tier Tech Support</p>
        </>,
        mentorship: <>
            {
                promo ? 
                    <>  
                        <p>{`Regular Price $899`}</p>
                        {earlySpecialHTML}
                    </>
                :
                <></>
            }
            <p>Same Options As Tax Office</p>
            <p style={mentorshiptStyle}><strong>+ Added Value:</strong></p>
            <p><strong>Personal</strong> Mentorship Sessions</p>
            <p><strong>Advanced</strong> Marketing</p>
            <p ><strong>Advanced</strong> Sales</p>
        </>
    };
}