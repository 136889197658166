
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useThemeContext } from "../../../hooks/usethemeContext";
import {
  PayPalScriptProvider,
  FUNDING,
  // PayPalComponents,
  PayPalButtons,
  PayPalMarks
} from "@paypal/react-paypal-js";


const PayPal = (props) => {
  const [succeeded, setSucceeded] = useState(false);
  const [paypalErrorMessage, setPaypalErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);
  const [billingDetails, setBillingDetails] = useState("");
  const [orderData, setOderData] = useState({});
  const fundingSources = [FUNDING.PAYPAL, FUNDING.CARD];
  const [selectedFundingSource, setSelectedFundingSource] = useState(fundingSources[0]);

  // define styles by darkMode
  const { darkMode } = useThemeContext();
  
  const navigate = useNavigate();

  useEffect(() => {

  }, [selectedFundingSource])

  const initialOptions = {
    // "client-id": process.env.REACT_APP_YOUR_CLIENT_ID_SANDBOX,
    "client-id": process.env.REACT_APP_YOUR_CLIENT_ID_LIVE,
    components: "buttons,marks,funding-eligibility",
  };

  const currency = 'USD';

  const PayPalButtonsStyle = { color: darkMode ? 'white' : 'black', };

  const formLabelBackground = darkMode ?  'bg-success' : 'bg-body';

  function onChange(e) {
	  setSelectedFundingSource(e.target.value);
	};

  // creates a paypal order
  const createOrder = async (data, actions) => {
    return await actions.order
      .create({
        purchase_units: [
          {
            description: `Professional ${props.deal} Software Package`,
            amount: {
              currency_code: currency,
              value: props.price,
            },
          },
        ],
        // remove the applicaiton_context object if you need your users to add a shipping address
        // application_context: {
        //   shipping_preference: "NO_SHIPPING",
        // },
      })
      .then((orderID) => {
        setOrderID(orderID);
        return orderID;
      });
  };

  // handles when a payment is confirmed for paypal
  const onApprove = async (data, actions) => {
    const orderInfo = await actions.order.capture(data.orderID).then( (results) => {return results});

    return await actions.order.capture().then(function (payerData) {
      
      const { payer } = payerData;
      
      setOderData(orderInfo);
      setBillingDetails(payer);
      setSucceeded(true);

      // Show a success message within this page, e.g.
      // Or go to another URL:
      setTimeout(() => {
        // actions.redirect('/success');
        navigate('/Success', { state: { orderInfo: orderInfo, deal: props.deal } });
      }, 2000)

    });
  };
  // handles payment errors
  const onError = async (data, actions) => {
    setPaypalErrorMessage("Something went wrong with your payment");
  };


  return (
    <>
      <div key={props.id}>
        <PayPalScriptProvider options={initialOptions}>
          <form style={{ minHeight: "100px" }}>
            {fundingSources.map((fundingSource) => (
              <label className={`mark ${formLabelBackground}`} key={fundingSource}>
                <input
                  defaultChecked={
                    fundingSource === selectedFundingSource
                  }
                  onChange={onChange}
                  type="radio"
                  name="fundingSource"
                  value={fundingSource}
                />
                <PayPalMarks fundingSource={fundingSource} className={`${formLabelBackground}`}/>
              </label>
            ))}
          </form>
          <PayPalButtons
            // disabled
            fundingSource={selectedFundingSource}
            style={PayPalButtonsStyle}
            forceReRender={[selectedFundingSource, darkMode]}
            createOrder={createOrder}
            onApprove={onApprove}
            onError={onError}
          />
        </PayPalScriptProvider>
      </div>
    </>
  );
};

export default PayPal;