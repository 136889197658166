
import { useEffect, useState } from 'react';
import { useThemeContext } from "../../hooks/usethemeContext";
import { switchTheme } from '../../assets/styles';
import { getTaxSoftwareText, dealType } from './helpers/taxSoftware';
import { Container, Col, Row } from "react-bootstrap";
import { Card } from 'react-bootstrap';
import PayPal from './buttons/PayPal';


export default function Pricing() {

    const [promo, setPromo] = useState(true)
    
    // define styles by darkMode
    const { darkMode } = useThemeContext();
    const { dealStyles, buttonColors, pricingBackgroundColor } = switchTheme(darkMode);
    const sm = 1, lg = 3;
    // const buttonVariant = darkMode ? 'outline-secondary' : 'outline-secondary';
    
    // Promotions/Deals
    const promoDate = '16 Dec 2022';
    const taxOffice = promo ? 99 : 299;
    const taxAffiliate = promo ? 99 : 399;
    const mentorship = promo && taxOffice > 199 ? taxOffice + 200 : taxOffice + 600;
    const earlySpecialHTML = <p className='text-muted'>Offer Expires {promoDate}</p>;

    const taxSoftwareText = getTaxSoftwareText({ color: buttonColors.blue }, promo, earlySpecialHTML);

    function checkPromo() {
        if(Date.parse(`${promoDate} 00:00:00 EST`) < new Date()) setPromo(false);
    };
    
    function callEverySecond() {
        setInterval(checkPromo, 1000 * 1);// checks every second
    };
    
    useEffect(() => {
        let today = new Date();
        checkPromo();
        if (today.getSeconds() === 0) { // You can also check for minutes, hours, etc
            callEverySecond();
        } else {
            today.setHours(today.getHours());
            today.setMinutes(today.getMinutes());
            today.setSeconds(today.getSeconds() + 1);// adds 1 second to the current time
            let difference = today - new Date();
            setTimeout(callEverySecond, difference);
        }
    }, [promo]);


    return <>
        <div className='pt-4 pb-5'>
            <Container>
                <div className="text-center">
                    <h2>Free Software Setup</h2>
                    <h5>Software & IRS Certified Training in English & Spanish Available
                    </h5>
                    <h5><strong>Free - How To Apply For an EFIN & EIN </strong> - I Personally Guide You Through <strong>YOUR </strong> Application</h5>
                </div>
                <Row className={`justify-items-center pricing`} xs={1} sm={sm} lg={lg}>
                    <Col>
                        <Card className={`bootstrap-card shadow ${pricingBackgroundColor} rounded text-center mt-2 mb-5`}>
                            <Card.Header as={'h4'} style={dealStyles}>
                                {dealType.taxOffice}
                            </Card.Header>
                            <Card.Body>
                                <Card.Title as={'h1'}>
                                    {taxOffice} <small className="text-muted">/ yr</small>
                                </Card.Title>
                                <Card.Text as={'h5'}>
                                    <ul className="list-unstyled mt-3 mb-4">   
                                        {taxSoftwareText.taxOffice}
                                    </ul>
                                </Card.Text>
                                <div className="d-grid position-sticky top-100">
                                    <PayPal id={1} price={taxOffice} deal={dealType.taxOffice}/>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className={`bootstrap-card shadow ${pricingBackgroundColor} rounded text-center mt-2 mb-5`}>
                            <Card.Header as={'h4'} style={dealStyles}>
                                {dealType.taxAffiliate}
                            </Card.Header>
                            <Card.Body>
                                <Card.Title as={'h1'}>
                                {taxAffiliate} <small className="text-muted">/ yr</small>
                                </Card.Title>
                                <Card.Text as={'h5'}>
                                    <ul className="list-unstyled mt-3 mb-4">
                                        {taxSoftwareText.taxAffiliate}
                                    </ul>
                                </Card.Text>
                                <div className="d-grid position-sticky top-100">
                                    <PayPal id={2} price={taxAffiliate} deal={dealType.taxAffiliate}/>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className={`bootstrap-card shadow ${pricingBackgroundColor} rounded text-center mt-2 mb-5`}>
                            <Card.Header as={'h4'} style={dealStyles}>
                                {dealType.mentorship}
                            </Card.Header>
                            <Card.Body>
                                <Card.Title as={'h1'}>
                                    {mentorship}
                                </Card.Title>
                                <Card.Text as={'h5'}>
                                    <ul className="list-unstyled mt-3 mb-4">     
                                        {taxSoftwareText.mentorship}
                                    </ul>
                                </Card.Text>
                                <div className="d-grid position-sticky top-100">
                                    <PayPal id={3} price={mentorship} deal={dealType.mentorship}/>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
  </>;
}
